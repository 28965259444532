import { AppBar, Button, Grid, IconButton, Toolbar, Typography } from "@material-ui/core"
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import styled from 'styled-components'

const NavBar = () => {
  return (
    <AppBar position="static">
      <ToolBarPadding>
        <Typography variant="h6">Visalia LAN</Typography>
        <Button disableElevation variant='contained' color='primary' startIcon={<HomeIcon />} style={{ marginLeft: 15 }} href='/'>
          Home
        </Button>
        <Button disableElevation variant='contained' color='primary' startIcon={<AssignmentIcon />} style={{ marginLeft: 15 }} href='/application'>
          Scholarships
        </Button>
      </ToolBarPadding>
    </AppBar >
  )
}

export default NavBar

let ToolBarPadding = styled(Toolbar)`
  padding-left: calc((100vw - 1232px) /2);
`