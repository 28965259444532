import { Box, Container, Divider, Link, Typography } from "@material-ui/core"

const marginTop15 = {
  marginTop: 15,
}

const AboutPage = () => {
  return (
    <Box height='105vh'>
      <Container maxWidth='lg'>
        <Typography variant='h4' align='left' style={marginTop15}>About</Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          The Visalia LAN aims to localize an alumni network of Visalia graduates who may no longer live in Visalia but
          who still want to affect positive change in our hometown. Alumni can fund scholarships, help with scholarship
          selections, host events, or offer to mentor a current VUSD student. Get involved by emailing <Link href='mailto:info@localalumninetwork.com'>info@localalumninetwork.com</Link>
        </Typography>
        <Divider style={marginTop15}></Divider>
        <Typography variant='h4' align='left' style={marginTop15}>Get Involved</Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          VLAN is always looking for alumni to get involved!
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          Some ways that you can help:
          <ul>
            <li>Host a Career Talk to showcase what you do</li>
            <li>Fund a scholarship for a VUSD student</li>
            <li>Join a selection committee to help determine how scholarships are being awarded</li>
            <li>Offer to mentor a student interested in your field or give advice to a first-generation college student </li>
          </ul>
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          Email <Link href='mailto:info@localalumninetwork.com'>info@localalumninetwork.com</Link> to join the Visalia LAN
        </Typography>
      </Container>
    </Box>
  )
}

export default AboutPage