import { BorderBottom } from "@material-ui/icons"

const { Container, Typography, Box, Card, Grid, Divider } = require("@material-ui/core")
const { default: HomeCard } = require("../../components/cards/HomeCard")

const scholarshipBody = "Applications are due by 3/14/21. Click below to find the prompt and to submit the application"
const upcomingEventsBody = "Check back at a later time to see what events we have scheduled"
const aboutBody = "Interested in getting involved? Want to learn more? Find out here!"

const HomePage = () => {
  return (
    <Box>
      <Box style={{ padding: 30, marginBottom: 30, minHeight: '15vw' }} display='flex' justifyContent='center' alignItems='center'>
        <Grid container justify='center' alignItems='center'>
          <Typography variant="h2">Visalia Local Alumni Network</Typography>
          <Container>
            <Typography variant="h6" align='left' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              VLAN is a localized alumni network from Visalia, CA. Our goal is to affect change in Visalia by providing scholarship opportunities,
              hosting events, and connecting students with professions that may be hard to find in Visalia.
          </Typography>
          </Container>
        </Grid>
      </Box>
      <Divider />
      <Box style={{ paddingTop: "5vh", height: '50vw', backgroundColor: 'rgb(247, 249, 250)' }}>
        <Container>
          <Grid container spacing={3} style={{ flexGrow: 1 }}>
            <Grid item xs={12} lg={4}>
              <HomeCard title="Scholarship Application" body={scholarshipBody} icon="application" actionTitle="Apply Here" actionUrl="/application" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <HomeCard title="Upcoming Events" body={upcomingEventsBody} icon="calendar" actionTitle="TBD" actionUrl="#" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <HomeCard title="About" body={aboutBody} icon="about" actionTitle="About" actionUrl="/about" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default HomePage