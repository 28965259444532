import { Button, Card, CardActions, CardContent, Icon, Link, Typography } from "@material-ui/core"
import EventIcon from '@material-ui/icons/Event';
import InfoIcon from '@material-ui/icons/Info';
import AssignmentIcon from '@material-ui/icons/Assignment';

const HomeCard = ({ title, icon, body, actionTitle, actionUrl }) => {
  return (
    <Card style={{ minHeight: '15vw' }}>
      <CardContent>
        {getIcon(icon)}
        <Typography variant="h5" style={{ fontWeight: 'bold', marginTop: 15 }}>{title}</Typography>
        <Typography style={{ marginTop: 15 }}>{body}</Typography>
      </CardContent>
      {actionUrl && <CardActions style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" href={actionUrl}>
          {actionTitle}
        </Button></CardActions>}
    </Card>
  )
}

function getIcon(icon) {
  switch (icon) {
    case "calendar":
      return <EventIcon fontSize="large" style={{ color: 'rgba(0, 0, 0, 0.7)' }} />
    case "application":
      return <AssignmentIcon fontSize="large" style={{ color: 'rgba(0, 0, 0, 0.7)' }} />
    case "about":
      return <InfoIcon fontSize="large" style={{ color: 'rgba(0, 0, 0, 0.7)' }} />
  }
}

export default HomeCard