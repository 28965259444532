import { Box, Button, Container, Divider, Link, Typography } from "@material-ui/core"

const marginTop15 = {
  marginTop: 15,
}

const ApplicationPage = () => {
  return (
    <Box height='105vh'>
      <Container maxWidth='lg'>
        <Typography variant='h4' align='left' style={marginTop15}>Deadline</Typography>
        <Typography variant='body1' align='left' style={marginTop15}>Applications are due <strong>March 14, 2021 @ 11:59 PM</strong></Typography>
        <Typography variant='body1' align='left' style={marginTop15}>Late applications will not be accepted.</Typography>
        <Box align='left'>
          <Button variant='contained' color='primary' style={marginTop15} href='https://forms.gle/4ECqnUgAzesyb7Lb9'>Submit Your Application Here</Button>
        </Box>
        <Divider style={marginTop15} />
        <Typography variant='h4' align='left' style={marginTop15}>About</Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          Up to $2,000 in scholarships will be awarded to MWHS students that identify with an underrepresented or minority community and
          that demonstrates a willingness to work toward a more equitable society.
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          Examples of underrepresented or minority communities include, but not limited to, the following groups:
        </Typography>
        <ul align='left' style={marginTop15}>
          <li>Black, Indigenous, or Persons of Color (BIPOC)</li>
          <li>LGTBQ+</li>
          <li>Persons with disabilities</li>
          <li>Underrepresented in your field of study (ex. Women in STEM)</li>
        </ul>
        <Divider style={marginTop15} />
        <Typography variant='h4' align='left' style={marginTop15}>Prompt</Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          <strong>Write about something in your life that is significant to you.</strong>
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          This prompt is very open ended - write about something that has happened to you or that you have witnessed that resonates with you.
          Maybe it’s why you’ve picked your major, what motivates you, or what has shaped your worldview. It could be something that you’ve lived
          through or a problem with the world that you’re passionate about solving.
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          <strong>Alternative: If you had the same amount of wealth as Bill Gates, how would you give it all away?</strong>
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          You are starting with 118.7 billion dollars. Every year, you gain 10% of your remaining net worth. So if you did nothing with your starting cash for 1 year,
          you would have 130.57 billion dollars next year. You have 30 years to reach $0 and to make the largest positive impact on the world.
        </Typography>
        <Divider style={marginTop15} />
        <Typography variant='h4' align='left' style={marginTop15}>Submitting Your Application</Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          Submit your application as a PDF following the naming convention <strong>firstname_lastname_mmddyyyy.pdf</strong>
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          Where mmddyyyy is the date that you have submitted your application.
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>
          Ex. sally_doe_03142021.pdf
        </Typography>
        <Typography variant='body1' align='left' style={marginTop15}>If you have any questions, feel free to reach out to <Link href='scholarships@localalumninetwork.com'>scholarships@localalumninetwork.com</Link></Typography>
        <Typography variant='body1' align='left' style={marginTop15}>As a bonus, you may request feedback if your application is submitted before March 1, 2021.</Typography>
        <Box align='left'>
          <Button variant='contained' color='primary' style={marginTop15} href='https://forms.gle/4ECqnUgAzesyb7Lb9'>Submit Your Application Here</Button>
        </Box>
      </Container>
    </Box >
  )
}

export default ApplicationPage