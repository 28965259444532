import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Palette } from '@material-ui/icons';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1976d2'
    }
  },
  typography: {
    h1: {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    h2: {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    h3: {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    h4: {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    h5: {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
