import logo from './logo.svg';
import './App.css';
import 'fontsource-roboto';
import NavBar from './components/nav/NavBar';
import HomePage from './pages/home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ApplicationPage from './pages/application';
import AboutPage from './pages/about';
import EventsPage from './pages/events';


function App() {
  return (
    <div className="App">
      <NavBar />
      <Router>
        <Route path='/application'>
          <ApplicationPage />
        </Route>
        <Route path='/events'>
          <EventsPage />
        </Route>
        <Route path='/about'>
          <AboutPage />
        </Route>
        <Route exact path='/'>
          <HomePage />
        </Route>
      </Router>
    </div>
  );
}

export default App;
